import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) => ({
  matchingDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '1rem',
    overflow: 'hidden',
  },
  matchingImage: {
    '& img': {
      width: '100%',
    },
    alignItems: 'center',
    display: 'flex',
    marginRight: '1rem',
    minWidth: '57px',
    width: '57px',
  },
  matchingInfo: {
    display: 'flex',
  },
  matchingProduct: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '9px',
    textDecoration: 'none',
  },
  matchingText: {
    color: ColorsPalette.primary,
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '19px',
    textOverflow: 'ellipsis',
  },
}));
