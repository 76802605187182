import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) => ({
  '@keyframes stk-animation-close': {
    '0%': {
      height: 'auto',
      opacity: 1,
    },
    '100%': {
      height: 0,
      opacity: 0,
      padding: '0 16px',
    },
    '50%': {
      height: 0,
      opacity: 0,
    },
  },
  '@keyframes stk-animation-open': {
    '0%': {
      height: 0,
      opacity: 0,
    },
    '100%': {
      height: 'auto',
      opacity: 1,
    },
    '95%': {
      height: '160px',
      opacity: 1,
    },
  },
  animationClose: {},
  animationOpen: {},
  clearSearchHistory: {
    '&:hover': {
      color: ColorsPalette.hoverActionCTA,
    },
    color: ColorsPalette.primary,
    cursor: 'pointer',
    fontFamily: FontCollection.raleway,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '160%',
    marginLeft: '0.7rem',
    textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },
  content: {},
  recent: {
    width: '100%',
  },
  recentSearches: {
    display: 'flex',
    flexDirection: 'column',
  },
  trands: {
    '&$withProducts': {
      marginBottom: '1rem',
    },
    flex: '1',
    marginBottom: '1rem',
    paddingLeft: 16,
    paddingRight: '1rem',
    [theme.breakpoints.down('md')]: {
      paddingRight: '0',
    },
  },
  withProducts: {},
  wrapper: {
    '& $content': {
      '&$animationClose': {
        animationDuration: '2s',
        animationName: '$stk-animation-close',
        display: 'flex',
      },
      '&$animationOpen': {
        animationName: '$stk-animation-open',
        display: 'flex',
      },
      '&$withProducts': {
        paddingBottom: '11px',
      },
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-thumb': {
        background: ColorsPalette.tertiary,
        borderRadius: 10,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: ColorsPalette.tertiary,
      },
      '&::-webkit-scrollbar-track': {
        background: ColorsPalette.neutral2,
        borderRadius: 10,
      },
      animationDuration: '0.25s',
      animationFillMode: 'forwards',
      backgroundColor: ColorsPalette.neutral2,
      borderRadius: '0  0 12px 12px',
      borderStyle: 'solid',
      borderWidth: 0,
      boxSizing: 'border-box',
      color: ColorsPalette.tertiary,
      display: 'none',
      flexDirection: 'column',
      //   flexWrap: 'wrap',
      justifyContent: 'space-between',
      maxHeight: 'calc(100vh - 100px)',
      overflow: 'auto',
      padding: '40px 0 40px 0',
      scrollbarWidth: 'thin',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        animation: 'none',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        padding: '24px 16px',
      },
    },
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '30px',
    width: '100%',
    zIndex: 3,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: '0',
    },
  },
}));
