import React from 'react';
import { useSelector } from 'react-redux';

import Link from '@components/common/Link';
import ProductFullResponsiveDataPrice from '@components/common/ProductFullResponsive/ProductDataPrice';
import { PageTypes } from '@constants/pageTypes';
import { IPageDataReducer } from '@interfaces/pageData/reducer';
import { IProductInList } from '@interfaces/product';
import { IStore } from '@interfaces/store';
import { generateProductUrl } from '@utils/common';
import { searchEvent } from '@utils/gtag';
import { isProductGiftCard } from '@utils/product';

import useStyles from './styles';

interface IMatchingProductProps {
  product: IProductInList;
}

const MatchingProduct: React.FunctionComponent<IMatchingProductProps> = ({ product }) => {
  const classes = useStyles();

  const searchValue = useSelector<IStore, string>((state: IStore) => state.search.inputValue);
  const { data }: IPageDataReducer = useSelector((state: IStore) => state.pageData);
  const { url: productDetailSlug } = data?.product_detail_page || { url: '' };

  const giftCardPage = data.pages?.find((pageItem) => pageItem.type === PageTypes.giftCard);
  const {
    brand: { brand },
    model,
    colors,
  } = product;
  const {
    pictures,
  } = colors[0];

  const isGiftCard = isProductGiftCard(product);
  const productUrl = isGiftCard && giftCardPage ? '/' + giftCardPage.url : generateProductUrl(product, productDetailSlug);

  const handleClick = () => {
    if (searchValue) {
      searchEvent(searchValue);
    }
  };

  return (
    <Link legacyBehavior href={productUrl} pageType={PageTypes.productDetail}>
      <a className={classes.matchingProduct} onClick={handleClick} data-action="search">
        <div className={classes.matchingInfo}>
          <div className={classes.matchingImage}>
            <img src={pictures[0].urlThumbnail} />
          </div>
          <div className={classes.matchingDescription}>
            <div className={classes.matchingText}>{brand}</div>
            <div className={classes.matchingText}>{model}</div>
          </div>
        </div>
        <ProductFullResponsiveDataPrice item={product} toCol={true} />
      </a>
    </Link>
  );
};

export default MatchingProduct;
