import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  acSearchHistoryClearAction,
  acSearchHistoryGetAction,
  acSearchTrendingGetAction,
  acShowSearchResults,
} from '@actions/acSearch';
import ResultBlockHeader from '@components/layout/header/AppBar/Search/ResultBlockHeader';
import ResultItemString from '@components/layout/header/AppBar/Search/ResultString';
import MatchingCategories from '@components/layout/header/AppBar/Search/SearchInfo/MatchingCategories';
import { useTranslation } from '@hooks/useTranslation';
import { TThunkDispatch } from '@interfaces/index';
import { IProductInList } from '@interfaces/product';
import { ISearchHistory } from '@interfaces/search';
import { IStore } from '@interfaces/store';
import SearchMatchingProducts from './SearchMatchingProducts/SearchMatchingProducts';
import SearchTrendyKeyword from './SearchTrendyKeyword/SearchTrendyKeyword';

import useStyles from './styles';

interface ISearchInfoProps {
  toggleSearchMobileMenu?: () => void;
}

const SearchInfo: React.FunctionComponent<ISearchInfoProps> = ({ toggleSearchMobileMenu }) => {
  const classes = useStyles();
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();

  const matchingProducts = useSelector<IStore, IProductInList[]>((state: IStore) => state.search.matchingProducts);
  const inputValueFromStore = useSelector<IStore, string>((state: IStore) => state.search.inputValue);
  const isOpenPanel = useSelector<IStore, boolean>((state: IStore) => state.search.isOpenPanel);

  const [keywords, setKeywords] = useState([]);
  const [recentSearches, setRecentSearches] = useState<ISearchHistory[]>([]);
  const [loadedKeywords, setLoadedKeywords] = useState([]);
  const [canShowAnimation, setCanShowAnimation] = useState(false);
  const { t } = useTranslation();

  const matchingProductsExist = !!matchingProducts.length;
  const showRecentSearches = !!recentSearches?.length && !inputValueFromStore;
  const showKeywords = !!keywords.length && !inputValueFromStore;
  const onShowSearchResults = useCallback((value: ISearchHistory) => dispatch(acShowSearchResults(value.searchText)), [dispatch]);
  const onGetTrendingItems = useCallback<() => Promise<any>>(() => dispatch(acSearchTrendingGetAction()), [dispatch]);
  const onGetSearchHistory = useCallback<() => Promise<any>>(() => dispatch(acSearchHistoryGetAction()), [dispatch]);
  const onClearSearchHistory = useCallback<() => Promise<any>>(() => dispatch(acSearchHistoryClearAction()), [dispatch]);

  const clearSearchHistory = () => {
    onClearSearchHistory().then(() => {
      setRecentSearches([]);
    });
  };

  useEffect(() => {
    if (!canShowAnimation && isOpenPanel) {
      setCanShowAnimation(true);
    }
  }, [isOpenPanel]);

  useEffect(() => {
    if (isOpenPanel) {
      onGetTrendingItems().then(({ payload: items }) => {
        const trendingItems = items.map(({ searchText }) => searchText);
        setKeywords(trendingItems);
        setLoadedKeywords(trendingItems);
      });
      onGetSearchHistory().then(({ payload }) => {
        if(payload){
          setRecentSearches(payload);
        }
      });
    }
  }, [isOpenPanel]);

  useEffect(() => {
    const inputValue = inputValueFromStore.toLowerCase();
    if (inputValue === '') {
      setKeywords(loadedKeywords);
    } else {
      setKeywords(
        loadedKeywords.filter((keyword: string) => {
          return keyword.toLowerCase().startsWith(inputValue);
        }),
      );
    }
  }, [inputValueFromStore, loadedKeywords]);

  return (
    <div className={classes.wrapper}>
      <div
        className={classNames(
          classes.content,
          {
            [classes.animationClose]: !isOpenPanel && canShowAnimation,
            [classes.animationOpen]: isOpenPanel && canShowAnimation,
            [classes.withProducts]: matchingProductsExist,
          },
        )}
      >
        {showKeywords && (
          <div className={classNames(classes.trands, { [classes.withProducts]: matchingProductsExist })}>
            <ResultBlockHeader>{t('search.trendingSearches')}</ResultBlockHeader>
            <div>
              {keywords.map((keyword, index) => {
                return (
                  <SearchTrendyKeyword text={keyword} key={index} toggleSearchMobileMenu={toggleSearchMobileMenu} />
                );
              })}
            </div>
          </div>
        )}
        {showRecentSearches && (
          <div className={classes.recent}>
            <ResultBlockHeader margin={true}>
              {t('search.recent')}
              <div className={classes.clearSearchHistory} onClick={clearSearchHistory}>
                {t('search.clear')}
              </div>
            </ResultBlockHeader>
            <div className={classes.recentSearches}>
              {recentSearches.map((search, index) => (
                <ResultItemString key={index}>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      onShowSearchResults(search);
                    }}
                  >
                  {search.searchText}
                </span>
                </ResultItemString>
              ))}
            </div>
          </div>
        )}
        <MatchingCategories />
        {matchingProductsExist && <SearchMatchingProducts toggleSearchMobileMenu={toggleSearchMobileMenu} />}
      </div>
    </div>
  );
};

export default SearchInfo;
