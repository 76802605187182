import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import Link from '@components/common/Link';
import ParseHtml from '@components/common/ParseHtmlToReact';
import ResultBlockHeader from '@components/layout/header/AppBar/Search/ResultBlockHeader';
import { useTranslation } from '@hooks/useTranslation';
import { IWrappedCategorySectionMapped } from '@interfaces/category';
import { IPageReference } from '@interfaces/pageData/reducer';
import { ISearchCategory } from '@interfaces/search';
import { IStore } from '@interfaces/store';
import { getSexIdTranslationKey } from '@utils/gender';
import { getCategoryUrlById, getPageBySexId } from '@utils/routes';
import { searchEvent } from '@utils/gtag';

import styles from './styles';

const MatchingCategories: React.FunctionComponent<{}> = () => {
  const classes = styles();
  const { t } = useTranslation();
  const matchingCategories = useSelector<IStore, ISearchCategory[]>((state: IStore) => state.search.matchingCategories || []);
  const categoryWrapped = useSelector<IStore, IWrappedCategorySectionMapped[]>((state: IStore) => state.pageData.categoryWrapped || []);
  const pages = useSelector<IStore, IPageReference[] | undefined>((state: IStore) => state.pageData.data.pages);
  const searchValue = useSelector<IStore, string>((state: IStore) => state.search.inputValue);
  if (!matchingCategories.length) {
    return null;
  }

  const handleClick = () => {
    if (searchValue) {
      searchEvent(searchValue);
    }
  };

  const wrapToLink = (child: ReactNode, url: string | null, key): ReactNode => {
    if (url) {
      return (<Link className={classes.matchingCategoriesItemLink} key={key} href={url} data-action="search" onClick={handleClick}>{child}</Link>);
    }
    return child;
  };

  const renderCategory = (category: ISearchCategory): ReactNode => {
    const sexUrl = getPageBySexId(pages || [], category.sexId);
    const categoryUrl = getCategoryUrlById(category.categoryId, category.sexId, categoryWrapped);
    const url = sexUrl ? sexUrl.url + '/' + categoryUrl : null;
    const sexTranslation = getSexIdTranslationKey(category.sexId);
    return wrapToLink(<div className={classes.matchingCategoriesItem} key={`matchCat${category.categoryId}`}>
      <div className={classes.matchingCategoriesItemName}>
        {sexTranslation && (<>
          {t(sexTranslation)} <span> {`>`} </span>
        </>)}
        <ParseHtml html={category.textSimilarity || '' } />
      </div>
      <div className={classes.matchingCategoriesItemCount}>{category.productCount}</div>
    </div>, url, `matchCatItem${category.categoryId}`);
  };

  const matchingCategoriesSorted = matchingCategories.sort((a, b) => a.sexId - b.sexId);
  return (<div className={classes.matchingCategories}>
    <ResultBlockHeader margin={true}>{t('search.matchingCategories')}</ResultBlockHeader>
    <div>
      {matchingCategoriesSorted.sort((a, b) => a.sexId - b.sexId).map(renderCategory)}
    </div>
  </div>);
};

export default MatchingCategories;
