import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { acShowSearchResults } from '@actions/acSearch';
import { TThunkDispatch } from '@interfaces/index';
import { searchEvent } from '@utils/gtag';

import useStyles from './styles';

interface ISearchTrendyKeywordProps {
  text: string;
  toggleSearchMobileMenu?: () => void;
}

const SearchTrendyKeyword: React.FunctionComponent<ISearchTrendyKeywordProps> = ({ text, toggleSearchMobileMenu }) => {
  const classes = useStyles();

  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const onShowSearchResults = useCallback((value) => dispatch(acShowSearchResults(value)), [dispatch]);

  return (
    <button
      data-action="search"
      className={classes.button}
      onClick={(e)=> {
        e.preventDefault();
        onShowSearchResults(text);
        searchEvent(text);
      }}
    >
      {text}
    </button>
  );
};

export default SearchTrendyKeyword;
