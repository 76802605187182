import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    [theme.breakpoints.down('md')]: {
        fontFamily: FontCollection.inter,
        fontSize: '18px',
        fontWeight: '700',
    },
    color: ColorsPalette.primary,
    display: 'flex',
    fontSize: '14px',
    fontWeight: 700,
    justifyContent: 'space-between',
    lineHeight: '22.4px',
    marginBottom: '1rem',
    position: 'relative',
  },
  margin: {
    margin: '0 11px 0 16px'
  },
}));


const ResultBlockHeader: React.FunctionComponent<{className?: string, margin?: boolean} & PropsWithChildren> = ({ children, className, margin = false }) => {
  const classes = useStyles();
  return (
      <div className={classNames(classes.header, className, {[classes.margin]: margin})}>
        {children}
      </div>
  );
};

export default ResultBlockHeader;
