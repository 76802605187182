import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acShowSearchResults } from '@actions/acSearch';
import SubmitButton from '@components/common/SubmitButton';
import ResultBlockHeader from '@components/layout/header/AppBar/Search/ResultBlockHeader';
import { useTranslation } from '@hooks/useTranslation';
import { TThunkDispatch } from '@interfaces/index';
import { IProductInList } from '@interfaces/product';
import { IStore } from '@interfaces/store';

import MatchingProduct from './MatchingProduct/MatchingProduct';
import useStyles from './styles';

interface ISearchMatchingProductsProps {
  toggleSearchMobileMenu?: () => void;
}

const SearchMatchingProducts: React.FunctionComponent<ISearchMatchingProductsProps> = ({ toggleSearchMobileMenu }) => {
  const classes = useStyles();
  const matchingProducts = useSelector<IStore, IProductInList[]>((state: IStore) => state.search.matchingProducts);
  const searchValue = useSelector<IStore, string>((state: IStore) => state.search.inputValue);
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const { t } = useTranslation();
  const existMatchingProducts = !!matchingProducts.length;
  const onShowSearchResults = useCallback((value) => dispatch(acShowSearchResults(value)), [dispatch]);

  return (
    <>
      {existMatchingProducts && (<>
          <div className={classes.matchingProductsContainer}>
            <ResultBlockHeader>{t('search.matchingProducts')}</ResultBlockHeader>
            {matchingProducts.map((product, index) => (
              <MatchingProduct key={index} product={product} />
            ))}

          </div>
          <div className={classes.viewAllContainer}>
            <SubmitButton
              className={classes.viewAll}
              onClick={(e) => {
                e.preventDefault();
                onShowSearchResults(searchValue);
              }}
              startIcon={null}
              endIcon={null}
            >
              {t('search.viewAll')}
            </SubmitButton>
          </div>
        </>
      )}
    </>
  );
};

export default SearchMatchingProducts;
