import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) => ({
  matchingCategories: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
    width: '100%',
  },
  matchingCategoriesItem: {
    '& match': {
      fontWeight: 700,
    },
    '&:first-child': {
      marginTop: 5,
    },
    '&:hover': {
      [theme.breakpoints.up('sm')]: {
        backgroundColor: ColorsPalette.coralPinkLight,
      },
      color: ColorsPalette.hoverActionCTA,
    },
    alignItems: 'center',
    color: ColorsPalette.primary,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: FontCollection.raleway,
    fontSize: 14,
    fontWeight: 400,
    height: 32,
    justifyContent: 'space-between',
    lineHeight: '22px',
    overflow: 'hidden',
    padding: '0 16px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  matchingCategoriesItemCount: {},
  matchingCategoriesItemLink: {
    textDecoration: 'none',
  },
  matchingCategoriesItemName: {
    '& span': {
      padding: '0 4px',
    },
  },
}));
