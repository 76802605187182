import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) => ({
  matching: {},
  matchingProductsContainer: {
    paddingLeft: 16,
    paddingRight: 11,
    width: '100%',
  },
  viewAll: {
    height: '44px!important',
    minWidth: '30%',
    width: 'fit-content!important',
  },
  viewAllContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 80
  },
}));
