import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';

export default makeStyles<Theme>((theme) => ({
  button: {
    alignItems: 'center',
    backgroundColor: '#EBEBEB',
    borderRadius: '100px',
    borderStyle: 'solid',
    borderWidth: 0,
    color: ColorsPalette.primary,
    cursor: 'pointer',
    fontWeight: 700,
    justifyContent: 'center',
    margin: 0,
    marginRight: '16px',
    marginTop: '16px',
    outline: 'none',
    padding: '12px',
    [theme.breakpoints.down('md')]: {
      height: '48px',
      padding: '16px',
    },
  },
}));
